@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

@font-face {
    font-family: "Trajan";
    src: url("../fonts/TrajanPro-Regular.woff");
    font-display: swap;
}

@font-face {
    font-family: "Trajan Bold";
    src: url("../fonts/TrajanPro-Bold.woff");
    font-display: swap;
}

p,
span,
a,
ul,
li,
strong,.fontFig {
    font-family: "Figtree", sans-serif !important;
}

.bg-transparent{
    background: transparent !important;
}
.homeBanner_slider {
    position: absolute;
    bottom: 100px;
    text-align: center !important;
    left: 50%;
    transform: translateX(-50%);
}

.homeBanner_slider .outline-base-btn {
    background: #FFF;
    border-radius: 5px;
    color: #000;
}

.homeBanner_slider .outline-base-btn:hover,
.homeBanner_slider .outline-base-btn:focus,
.homeBanner_slider .outline-base-btn:active {
    background: transparent !important;
    color: #FFF !important;
    border-color: #FFF !important;
}

/* .section-space {
    padding-top: 100px !important;
} */

.rcs_collection1_title h2 {
    font-size: 50px;
    font-family: "Trajan";
    margin: 0;
}

.rcs_collection1_box {
    position: relative;
    overflow: hidden;
}

.rcs_collection1_box img {
    width: 100%;
}

.rcs_collection1_box_btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    border: 2px solid #FFF;
    z-index: -1;
}

.rcs_collection1_box_btn {
    margin: 0;
    text-align: center;
    position: absolute;
    z-index: 9;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    place-content: center;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

.rcs_collection1_box_btn h3 {
    font-size: 50px;
    font-family: "Libre Baskerville";
    text-transform: capitalize;
    color: #FFF;
}

.rcs_collection1_box:hover .rcs_collection1_box_btn {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.rcs_collection_section1 .slick-slide>div {
    padding: 0 5px;
}

.rcs_collection1_box_btn>a {
    background-color: #FFF;
    color: #000;
    width: fit-content !important;
    margin: 0 auto;
    border-radius: 5px;
    display: inline-block !important;
    padding: 10px 40px !important;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

.slick-dots li button:before {
    font-size: 20px !important;
}

.slick-dots {
    position: static !important;
    margin-top: 50px !important;
}

.ring-builder-section {
    padding: 230px 0;
    color: #FFF;
}

.ring-builder-section h2 {
    font-size: 24px;
}

.ring-builder-section h2 span {
    display: block;
    font-size: 50px;
    font-family: "Trajan" !important;
}

.ring-builder-section p {
    font-size: 18px;
}

.ej_rings_imgs {
    text-align: center;
    padding-top: 20px;
}

.ej_rings_imgs h5 {
    font-size: 30px;
    font-weight: 600;
    font-family: "Trajan";
}
.ej_rings_imgs p {
    font-size: 18px;
    line-height: 1.5;
    color: #000;
}
.ej_rings_imgs a.btn.outline-base-btn {
    background: black;
    color: white;
    width: 30%;
    border-radius: 5px;
    font-weight: 700;
    /* width: -moz-fit-content; */
}
.ej_jews p {
    color: white;
}











.ring-builder-section .button-flex a {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    background-color: #FFF;
    border-radius: 5px;
    padding: 8px 25px;
}

.ring-builder-section .button-flex a:last-child {
    border-color: #FFF;
    background-color: transparent;
    color: #FFF;
}

.ring-builder-section .button-flex span {
    font-size: 24px;
    font-weight: 600;
    vertical-align: middle;
}

.rcs_ring_style_section {
    margin: 100px 0 !important;
    padding: 80px 0 60px !important;
    background-color: #f3f3f3;
}

.rcs_ring_style_section h2 {
    font-family: "Trajan";
    font-size: 50px;
}

.rcs_ring_style_section p {
    font-size: 18px;
}

.rcs_shop_by_diamond_box_main {
    margin-bottom: 0 !important;
}

.rcs_shop_by_diamond_box_main img {
    width: 85px;
    height: 85px;
    object-fit: contain;
    margin: 0 auto 15px;
    opacity: 1 !important;
}

.rcs_shop_by_diamond_box_main h3 {
    font-size: 16px;
    color: #000;
}

.rcs_image_section img {
    max-width: 100%;
}

.rcs_visit_showroom {
    padding: 100px 60px;
}

.rcs_visit_showroom img {
    max-width: 100%;
}

.rcs_visit_showroom h5 {
    font-size: 50px;
    font-family: "Tranjan";
    text-transform: uppercase;
}

.rcs_visit_showroom .content {
    padding: 0 60px;
    color: #FFF;
    display: grid;
    place-content: center;
    background: url("../images/fade-logo.png") no-repeat bottom right, #832729;
}

.rcs_visit_showroom p {
    font-size: 18px;
    line-height: 1.5;
}

.rcs_visit_showroom .inner-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.rcs_visit_showroom .inner-wrap a {
    background-color: #FFF;
    color: #000;
    border-radius: 5px;
    padding: 8px 30px;
    font-weight: 700;
    width: fit-content;
}

.repair-section {
    padding: 200px 0;
}

.repair-section h6 {
    font-size: 42px;
    font-family: "Trajan";
    color: #FFF;
}

.repair-section p {
    color: #FFF;
    font-size: 16px;
    line-height: 2 !important;
}

.repair-section a {
    background-color: #FFF;
    color: #000;
    border-radius: 5px;
    padding: 8px 30px;
    font-weight: 700;
    width: fit-content;
}

.insta_section {
    padding: 100px 0 0;
}

.insta_section h2 {
    font-size: 50px;
    font-family: "Trajan";
}

.insta_section p,
.insta_section p a {
    color: #7E7E7E;
    font-size: 18px;
}

.rcs_newsletter {
    background-color: #832729;
    padding: 70px 0 !important;
}

.rcs_newsletter h2 {
    font-family: "Trajan Bold";
    letter-spacing: 1px;
    margin: 0 0 10px !important;
}

.gs_newsletter_input_section input {
    background-color: #FFF !important;
    border-radius: 5px !important;
    color: #000 !important;
}

.gs_newsletter_input_section input::placeholder {
    color: #000 !important;
}

.gs_newsletter_input_section button#button-addon2 {
    background: #000;
    border-radius: 5px !important;
    color: #FFF;
    margin-left: 10px;
}

.rcs_footer_content_one p {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.rcs_footer_content_one_social_icn_div {
    width: 30px !important;
    height: 30px !important;
    opacity: 1 !important;
}

.rcs_footer_content_one_social_icn_div a {
    line-height: 28px !important;
}

.rcs_footer_content h2 {
    font-family: "Trajan" !important;
    font-size: 20px !important;
}

ul.rcs_footer_list li a {
    opacity: 1 !important;
    font-size: 18px !important;
    font-weight: 300 !important;
}

ul.rcs_footer_list li {
    font-weight: 400 !important;
    font-size: 18px !important;
}

.rcs_footer_bottom,
.rcs_footer {
    background-color: #000 !important;
}

.rcs_footer_bottom p {
    text-align: center;
    font-weight: 300 !important;
}

.rcs_footer_bottom {
    border-top: 1px solid #ffffff41 !important;
}

.rcs_footer {
    padding: 70px 0 30px !important;
}

.rcs_top_header {
    background: #832729 !important;
}

.rcs_top_head_content p {
    font-weight: 400 !important;
}

.rcs_right_side li img {
    width: 22px !important;
    height: 22px !important;
    opacity: 1 !important;
}

.rcs_right_side ul {
    gap: 20px !important;
}

.rcs_menu.menu_list_main {
    background: #f9f4f4;
}

#rcs_menu_header_top ul h6 a {
    font-weight: 500 !important;
}

#rcs_menu_header_top ul.rcs_header_menu {
    padding: 0 !important;
    justify-content: space-between !important;
}

.rcs_collection1_box.rcs_mobile_collection1_box {
    margin: 0 !important;
}

.rcs_collection1_box:hover {
    transform: translate(0px, 0px) !important;
}

img.rcs_shop_by_diamond_box_img_hover {
    display: block !important;
}

.rcs_shop_by_diamond_box_main {
    opacity: 1 !important;
}

.rcs_menu.menu_list_main {
    padding: 25px 0 0;
}

.rcs_menu.menu_list_main .rcs_top_header_menu h6 a {
    text-transform: uppercase;
    font-size: 18px;
    padding-bottom: 20px;
}

.rcs_megaMenu {
    top: 65px !important;
}

#rcs_menu_header_top {
    padding: 0 150px !important;
}

.rcs_large_Tab1 {
    padding-left: 150px !important;
}

.rcs_top_head_content_left {
    padding-right: 150px !important;
}

.rcs_testimonials {
    padding: 100px 0 0 !important;
}

.rcs_testimonials h5 {
    font-size: 50px;
    font-family: "Trajan";
    font-weight: normal;
}

.rcs_testimonials .subtitle {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
}

.rcs_testimonials h4 {
    font-size: 14px;
    font-weight: bold;
    font-family: 'Figtree';
    margin: 0;
}

.rcs_testimonials span {
    font-family: 'Figtree';
    font-size: 12px;
    color: #808080;
}

.rcs_testimonials .slick-arrow {
    border: 1px solid #832729 !important;
    border-radius: 100%;
    width: 24px;
    height: 24px;
}

.rcs_testimonials .slick-prev {
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23832729" class="bi bi-chevron-left" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/%3E%3C/svg%3E') no-repeat center/60% !important;
    bottom: -50px !important;
    left: 5px !important;
    top: auto !important;
    transform: translate(0) !important;
}

.rcs_testimonials .slick-next {
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23832729" class="bi bi-chevron-right" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/%3E%3C/svg%3E') no-repeat center/60% !important;
    bottom: -50px !important;
    right: 5px !important;
    top: auto !important;
    transform: translate(0) !important;
}

.rcs_testimonials .testimonial-block>img {
    text-align: center;
    display: block;
    margin: 0 auto 30px;
    height: 80px;
    object-fit: contain;
}

.rcs_testimonials .testimonial-block {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 50px 30px 100px;
    border-radius: 5px;
}

.faq_section {
    padding: 100px 0 0;
}

.faq_section h2 {
    font-size: 50px !important;
    font-family: "Trajan" !important;
    font-weight: normal;
    margin-bottom: 25px;
}

.faq_section .rcs_faq_accordion div[id*="panel1a-header"] {
    width: 100% !important;
}

.faq_section .rcs_faq_accordion div[id*="panel1a-header"] .MuiTypography-root.MuiTypography-body1 {
    font-size: 18px !important;
    font-weight: 500;
    font-family: "Figtree" !important;
}

.faq_section .rcs_faq_accordion>.MuiPaper-root.MuiPaper-elevation {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 8px 15px;
    margin-bottom: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.faq_section .rcs_faq_accordion>.MuiPaper-root.MuiPaper-elevation::before {
    display: none;
}

.rcs_testi_item {
    padding: 0 5px;
}



@media only screen and (min-width: 1600px) {
    .custom_container {
        padding: 0 250px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .rcs_visit_showroom p {
        font-size: 14px;
        line-height: 1.8;
    }

    .rcs_footer_content_one_social_icn li {
        margin-right: 10px !important;
    }

    .faq_section {
        padding: 100px 30px 0;
    }

    #rcs_menu_header_top ul.rcs_header_menu {
        padding: 0 !important;
    }

    .megamenu_showcase_center_header__3JnLM {
        padding: 5px 50px !important;
    }

    .w-40 {
        width: 70% !important;
    }

    .rcs_hero_slider {
        height: 600px;
        min-height: auto;
    }

    .homeBanner_slider {
        bottom: 80px;
    }

    .ring-builder-section {
        padding: 230px 30px !important;
    }

    .repair-section {
        padding: 80px 30px;
    }

    .rcs_news_content h2,
    .rcs_news_content1 h2 {
        margin-bottom: 0 !important;
    }

    #rcs_menu_header_top {
        padding: 0 80px !important;
    }

    .rcs_large_Tab1 {
        padding-left: 80px !important;
    }

    .rcs_top_head_content_left {
        padding-right: 80px !important;
    }

    .rcs_menu.menu_list_main {
        padding-top: 5px;
    }

    .rcs_megaMenu {
        top: 45px !important;
    }

    #rcs_menu_header_top ul h6 a {
        font-size: 14px !important;
    }

    .rcs_visit_showroom h5,
    .repair-section h6,
    .rcs_testimonials h5,
    .rcs_news_content h2,
    .rcs_news_content1 h2 {
        font-size: 30px !important;
    }

    .rcs_testimonials {
        padding: 100px 30px 0 !important;
    }

    .rcs_logo_section img {
        width: 250px;
    }
}

@media only screen and (max-width: 992px) {
    .rcs_logo_section {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .rcs_right_side ul li {
        font-size: 24px;
    }

    .rcs_header {
        padding: 5px !important;
    }

    .rcs_hero_slider::after {
        display: none !important;
    }

    .homeBanner_slider {
        width: 90%;
    }

    .rcs_hero_slider {
        height: calc(100vh - 500px) !important;
        min-height: fit-content;
    }

    .rcs_mobile_header_menu {
        left: 10px;
    }

    .ring-builder-section {
        padding: 70px 20px;
        color: #FFF;
    }

    .rcs_ring_style_section {
        margin: 50px 0 0 !important;
    }

    .rcs_visit_showroom h5,
    .rcs_testimonials h5 {
        font-size: 32px;
    }

    .rcs_visit_showroom .content {
        padding: 30px;
    }

    .rcs_visit_showroom p {
        font-size: 16px;
    }

    .rcs_visit_showroom img {
        height: 100%;
    }

    .rcs_visit_showroom,
    .repair-section {
        padding: 50px 20px;
    }

    .insta_section {
        padding: 50px 0 0;
    }

    .rcs_newsletter {
        padding: 50px 0 !important;
    }

    .gs_newsletter_input_section input {
        width: 70% !important;
    }

    section.rcs_mobile_footer_section {
        background-color: #000;
    }

    .rcs_footer {
        padding: 0 !important;
    }

    .rcs_testimonials .testimonial-block>img {
        width: auto;
        height: 80px;
    }

    .rcs_testimonials {
        padding: 50px 15px !important;
    }
}


@media (min-width:320px) and (max-width:768px) { 
    .ej_rings_imgs a.btn.outline-base-btn {
        width: 59%;
    }
    .ej_rings_imgs h5 {
        font-size: 25px;
    }
}



@media only screen and (max-width: 767px) {
    .w-40 {
        width: 100% !important;
    } 

    .homeBanner_slider {
        bottom: 0;
    }

    .rcs_testimonials .testimonial-block {
        margin: 15px 0;
    }

    .section-space {
        padding-top: 50px !important;
    }

    .rcs_collection1_title h2 {
        font-size: 23px !important;
    }

    .rcs_collection_section1 .slick-dots {
        margin-top: 20px !important;
    }

    .slick-dots li {
        margin: 0 5px !important;
    }

    .ring-builder-section h2 {
        margin-bottom: 0 !important;
    }

    .ring-builder-section h2 span {
        font-size: 30px;
    }

    .ring-builder-section p {
        font-size: 14px;
        width: 100%;
        margin: 0 auto !important;
    }

    .ring-builder-section .button-flex {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .ring-builder-section .button-flex a {
        width: 100%;
    }

    .ring-builder-section {
        padding: 300px 20px 50px;
    }

    section.rcs_ring_style_section {
        margin: 50px 0 !important;
        padding: 50px 0 !important;
    }

    .rcs_shop_by_diamond_title {
        margin: 0 auto !important;
        width: 90% !important;
    }

    .faq_section h2 {
        font-size: 24px !important;
    }

    .faq_section {
        padding-left: 16px;
        padding-right: 16px;
    }

    .rcs_ring_style_section p {
        width: 100% !important;
    }

    .rcs_shop_by_diamond_box_img_size {
        width: 110px !important;
        height: 110px !important;
    }

    .rcs_shop_by_diamond_box_img h3 {
        font-size: 18px !important;
        color: #000 !important;
        font-weight: 800 !important;
        text-transform: capitalize !important;
    }

    .rcs_visit_showroom {
        padding: 50px 0;
    }

    .rcs_visit_showroom .inner-wrap {
        grid-template-columns: repeat(1, 1fr);
    }

    .rcs_visit_showroom {
        text-align: center;
    }

    .rcs_visit_showroom h5,
    .repair-section h6,
    .rcs_testimonials h5 {
        font-size: 24px;
    }

    .rcs_visit_showroom p,
    .repair-section p {
        font-size: 14px;
        line-height: 1.8;
    }

    .rcs_visit_showroom .inner-wrap a {
        margin: 0 auto;
    }

    .repair-section {
        background: #000 !important;
    }

    .rcs_news_content1 h2,
    .rcs_news_content h2 {
        font-size: 30px !important;
        margin: 0 !important;
    }

    .insta_section p,
    .insta_section p a {
        font-size: 14px;
        color: #000;
    }

    .rcs_news_content p {
        font-size: 14px !important;
        margin-top: 10px !important;
    }

    .gs_newsletter_input_section button#button-addon2 {
        margin: 10px 0 0 !important;
        width: 100%;
        text-transform: uppercase !important;
    }

    .gs_footer_content {
        padding-top: 30px;
    }

    #rcs_footer_bottom {
        padding-top: 10px !important;
        background-color: #FFF !important;
        color: #000 !important;
    }

    .rcs_footer_bottom p {
        opacity: 1 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .rcs_footer_bottom p a {
        color: #000 !important;
    }

    .rcs_right_side ul li {
        font-size: 26px !important;
    }

    .rcs_ring_style_section .slick-prev {
        background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23832729" class="bi bi-chevron-left" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/%3E%3C/svg%3E') no-repeat center/cover !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        bottom: auto !important;
        left: 0 !important;
        margin: 0 auto 0 15px !important;
    }

    .rcs_ring_style_section .slick-next {
        top: 50% !important;
        background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23832729" class="bi bi-chevron-right" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/%3E%3C/svg%3E') no-repeat center/cover !important;
        transform: translateY(-50%) !important;
        bottom: auto !important;
        right: 0 !important;
        margin: 0 15px 0 auto !important;
    }

    .rcs_ring_style_section .slick-arrow {
        width: 25px !important;
        height: 25px !important;
        border: 1px solid #832729 !important;
    }

    
}

.popup-header button {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9;
    filter: invert(1);
    opacity: 1;
}